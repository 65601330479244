import {EnvName} from '../app/models/enums/env-name';
import {EnvConfig} from '../app/models/env-config';

import {BUILD_TIMESTAMP, COMMIT_HASH, VERSION} from './build';

export const env: EnvConfig = {
  name: 'S4N Soccer',
  environment: EnvName.PRODUCTION,
  version: VERSION,
  build: BUILD_TIMESTAMP,
  vapidPublicKey: 'BNwEO0kjhw017MbYnqD3Z_XCNoFtvFKxKFCSCH47Iiy94H8Zc-kZs6eQCEm0Y4PXrUWgGKPMLiYOXDCz7chVsI0',
  ably: {
    key: 'WRC7GA.3CVpYA:o7AQv5mygrp7_jrAu9D_0FGX4AHxJuT3pavvCMQ8ol4',
    channel: 'notifications',
    disconnectedTimeout: 5000,
    suspendedTimeout: 10000,
    heartbeatInterval: 10000
  },
  api: {
    url: 'https://api-old.golito.co',
    port: 443,
    version: 'v2',
    timeout: 15000
  },
  app: {
    activeEventBusLogger: false,
    activeFirebase: true,
    activeProdMode: true,
    activeRollbar: true,
    activeSW: true
  },
  facebook: {
    appId: '325412968024552',
    apiVersion: 'v20.0'
  },
  firebase: {
    apiKey: 'AIzaSyAp5_BG-MfOtxSeSxG2Xt56cbimwzSg54w',
    authDomain: 's4n-soccer-2018.firebaseapp.com',
    projectId: 's4n-soccer-2018',
    storageBucket: 's4n-soccer-2018.appspot.com',
    messagingSenderId: '44837555715',
    appId: '1:44837555715:web:9b5211704461dfdfd30836',
    measurementId: 'G-K2JSJYWBC0'
  },
  google: {
    clientId: '100409417876-2jr0j3entrj67oe1bjb3gg4t61rpmhou.apps.googleusercontent.com'
  },
  rollbar: {
    accessToken: 'a56728cc7371437db8562e0949af8224',
    codeVersion: COMMIT_HASH
  },
  safari: {
    id: 'web.co.golito.soccer',
    url: 'https://api-old.golito.co/v2/safari'
  }
};
